:root {
    --primary: #1e90ff;
    --white: #ffffff;
    --seondary: #51edfb;
    --dark: #000;
    --red: #DC3D46;
    --background: linear-gradient(0deg, rgb(58, 5, 119) 0%, rgb(74, 24, 180) 38%, rgb(142, 59, 244) 99%);
    --darkbg2: linear-gradient(90deg, rgba(0, 0, 20, 1) 0%, rgba(0, 0, 55, 1) 100%);
    --darkbg1: linear-gradient(90deg, rgba(29, 112, 138, 1) 0%, rgba(10, 38, 75, 1) 18%, rgba(22, 7, 71, 1) 81%, rgba(52, 0, 85, 1) 100%);
    --blockbg: linear-gradient(90deg, rgba(23, 23, 23, 0.85) 0%, rgba(24, 24, 24, 0.85) 35%, rgba(19, 19, 19, 0.85) 100%);
}

@font-face {
    font-family: "LatoRegular";
    src: local("LatoRegular"),
        url("./fonts/Lato/Lato-Regular.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "LatoBold";
    src: local("LatoBold"),
        url("./fonts/Lato/Lato-Bold.ttf") format("truetype");
    font-weight: bold;
}

/*div[class*='dark'] img {
    filter: invert(1) hue-rotate(180deg)
}

div[class*='dark'] {
    filter: invert(1) hue-rotate(180deg)
}*/

/*body[class*='dark'] img {
    filter: invert(1) hue-rotate(180deg)
}

body[class*='dark'] {
    filter: invert(1) hue-rotate(180deg) !important
}*/


#root,
.root,
main,
body {

    font-family: 'Open Sans', sans-serif;
    font-family: "LatoRegular";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}

.columns {
    max-width: 95% !important;
}

.full-width {
    width: 100%;
}

.navbar.is-warning .navbar-start>.navbar-item,
.navbar.is-warning .navbar-start .navbar-link,
.navbar.is-warning .navbar-end>.navbar-item,
.navbar.is-warning .navbar-end .navbar-link {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
}

.secondarycolor {
    color: var(--seondary) !important;
}


.whitecolor {
    color: var(--white) !important;
}

.whitebordercolor {
    border-width: 0px 1px 1px 1px !important;
    /* top right bottom left */
    border-style: solid !important;
    border-color: var(--white) !important;
    border-radius: 5px !important;
}


.whitebordercolor:not(:placeholder-shown) {
    border-width: 0px 1px 1px 1px !important;
    /* top right bottom left */
    border-style: solid !important;
    border-color: var(--white) !important;
    border-radius: 5px !important;
}

.redcolor {
    color: var(--red) !important;
}

.secondaryborderbottom {
    border-bottom: 2px solid var(--seondary) !important;
}


.react-select {
    background: var(--blockbg);
    color: var(--white) !important;
}

/* Anula los estilos de la clase .css-qc6sy-singleValue */
.react-select__single-value,
.react-select__placeholder {
    color: var(--seondary) !important;
}

.react-select__option--is-selected {
    background: var(--darkbg1);
}



.react-select__input {
    color: var(--white) !important;
}

/* Cambia el color de fondo y texto de la lista desplegable */
.react-select__menu {
    background: var(--blockbg);
    color: var(--white) !important;
}

.react-select__option:hover,
.react-select__option--is-focused {
    background: var(--darkbg2);
    color: var(--white) !important;
}

/* Cambia el color de texto de cada opción de la lista desplegable */
.react-select__option {
    color: var(--white) !important;
}



.navbar.is-warning {
    background-color: rgba(33, 37, 41, 1) !important;
}

.navbar {
    padding: 0px;
}

/* Color input seleccionado */
.css-1pahdxg-control {
    background-color: var(--blockbg) !important;
    border-color: var(--darkbg2) !important;
}

/* Color input sin seleccionar */
.css-1s2u09g-control {
    background-color: var(--blockbg) !important;
    border-color: "blue" !important;
}

.layoutSidenav {
    display: flex;
}

.layoutSidenav_nav {
    width: 225px;
    height: 100vh;
    z-index: 1038;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    flex-basis: 225px;
    flex-shrink: 0;
    transition: transform 0.15s ease-in-out;
    display: block;
}

.sb-sidenav-dark {
    background-color: #212529;
    color: rgba(255, 255, 255, 0.5);
}

.sb-topnav {
    z-index: 1039;
    border-bottom: 1px solid #141618;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
}

.sb-sidenav {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: nowrap;
}

.layoutSidenav_content {
    padding-left: 0px;
    top: 56px;
}

@media (min-width: 992px) {
    .layoutSidenav .layoutSidenav_content {
        margin-left: 0;
        transition: margin 0.15s ease-in-out;
    }

    .layoutSidenav_content {
        /*padding-left: 0;*/
        top: 56px;
    }
}

.layoutSidenav .layoutSidenav_content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    flex-grow: 1;
    min-height: calc(100vh - 56px);
    margin-left: -225px;
}

.nav-link {
    color: rgb(120, 120, 120) !important;
    text-decoration: none;
    display: flex !important;
    align-items: center;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    position: relative;
    margin-left: 10px;
    font-family: "LatoRegular";
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    justify-content: left;
    align-items: left;
}

.menuIcon {
    padding-left: 15px;
}

.nav-link:hover {
    color: var(--white) !important;
    font-weight: bold;
}

.nav-link .active {
    color: red !important;
}

.nav {
    flex-direction: column;
}

.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-heading {
    width: 100%;
    padding: 1.75rem 1rem 0.75rem;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
}

/* Iconos Menu */
.sb-nav-link-icon {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.25);
    margin-right: 0.5rem;
}

.sb-nav-link-icon:hover {
    color: black;
}

.nav-link:hover .sb-nav-link-icon {
    color: black;
    font-weight: bold;
}

/* Datatables */
div.dtsb-searchBuilder {
    justify-content: space-evenly;
    cursor: default;
    margin-bottom: 1em;
    text-align: left;
}

div.dtsb-searchBuilder div.dtsb-titleRow {
    justify-content: space-evenly;
    margin-bottom: .5em;
}

div.dtsb-searchBuilder div.dtsb-titleRow div.dtsb-title {
    display: inline-block;
    padding-top: 6px;
}

div.dtsb-searchBuilder div.dtsb-group {
    position: relative;
    clear: both;
    margin-bottom: .8em;
}

div.dtsb-searchBuilder div.dtsb-group div.dtsb-logicContainer {
    border: 1px solid;
    border-color: #cecece;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
}

div.dtsb-searchBuilder div.dtsb-group div.dtsb-logicContainer {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    position: absolute;
    margin-top: .8em;
    margin-right: .8em;
}

div.dtsb-searchBuilder div.dtsb-logicContainer {
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
    background: linear-gradient(to right, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
}

div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria {
    margin-bottom: .8em;
}

div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria select.dtsb-dropDown,
div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria input.dtsb-input {
    border: 1px solid;
    border-radius: 3px;
}

div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria select.dtsb-dropDown,
div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria input.dtsb-input {
    padding: .4em;
    margin-right: .8em;
    max-width: 20em;
    background-color: rgba(200, 200, 200, 0.3);
}

div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria select.dtsb-condition {
    border-color: #48b13c;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

select {
    box-sizing: border-box;
    -webkit-appearance: menulist;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: initial;
    border-right-color: initial;
    border-bottom-color: initial;
    border-left-color: initial;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    color: text;
    background-color: -webkit-control-background;
    align-items: center;
    white-space: pre;
    -webkit-rtl-ordering: logical;
    cursor: default;
}

div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria select.dtsb-value,
div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria input.dtsb-value {
    border-color: #0069ba;
}

div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria div.dtsb-buttonContainer {
    /*float: right;*/
    display: inline-block;
}

div.dtsb-criteria {
    display: flex;
}

/* Login */
.loginpage {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    background-color: #f8f9fd;
}

.loginpage {
    background: rgb(5, 62, 119);
    background: var(--darkbg2);
}

input[type="text"],
input[type="password"] {
    height: 25px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

input[type="submit"] {
    margin-top: 10px;
    cursor: pointer;
    font-size: 15px;
    background: #01d28e;
    border: 1px solid #01d28e;
    color: #fff;
    padding: 10px 20px;
}

input[type="submit"]:hover {
    background: #6cf0c2;
}

.button-container {
    display: flex;
    justify-content: center;
}

.login-form {
    background-color: rgba(0, 0, 0, 0.358);
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 1rem 4rem 2rem 4rem;
    min-width: 300px;
    border: 2px solid var(--seondary);
    box-shadow: 0 4px 8px 0 rgba(202, 137, 255, 0.138), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.loginfield {
    color: var(--seondary);
}

.loginblock {
    color: var(--seondary) !important;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.list-container {
    display: flex;
}

.error {
    color: red;
    font-size: 12px;
}

.title {
    font-size: 25px;
    margin-bottom: 20px;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 10px;
}

.container {
    padding: 20px;
    max-width: 100% !important;
    margin: auto;
    min-height: 100%;
    /*background: rgb(227, 218, 251);
    background: linear-gradient(90deg, rgba(227, 218, 251, 1) 0%, rgba(207, 232, 255, 1) 35%, rgba(183, 251, 224, 1) 100%);*/
    background: var(--darkbg1);
}

.css-ee20y-MuiPaper-root {
    background-color: none;
}

.idcompraventa {
    font-size: 32px;
    background-color: gray;
    padding-left: 20px;
    padding-right: 20px;
    display: inline-block;
}

.imgCartaMedium {
    display: block;
    height: 200px;
    margin: auto;
}

.imgcartatabla {
    height: 90px;
}

.menutitle {
    margin-left: 10px;
    font-family: "LatoBold";
    margin-left: 15px;
    margin-bottom: 10px;
    text-transform: none;
    color: var(--white);
}

.menuIcon {
    margin-right: 10px;
}

.centeredtext {
    text-align: center;
    margin: auto;
}

.greentr {
    background-color: rgba(118, 213, 118, 0.26) !important;
}

.redtr {
    background-color: rgba(213, 118, 118, 0.26) !important;
}

.greentext {
    color: rgb(4, 209, 4) !important;
}

.redtext {
    color: rgb(221, 2, 2) !important;
}

.MenuIcon {
    color: var(--white) !important;
}

.menupaper {
    width: 280px;
    background-color: transparent !important;
    background: var(--darkbg2) !important;
}

.MuiDrawer-paper {
    background-color: transparent !important;
    background: var(--darkbg2) !important;
}

.menupaperinner {
    background-color: transparent !important;
    background: none !important;
}


.menudiv {
    padding: 20px;
    display: flex;
    font-size: 24px;
    color: var(--white);
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    min-width: 280px;
}

.table td {
    text-align: center !important;
}

.rainbowborder {
    padding-bottom: 2px !important;
    background:
        linear-gradient(to left, #00C0FF 0%,
            #FFCF00 49%,
            #FC4F4F 100%) left bottom rgba(255, 255, 255, 0) no-repeat !important;
    background-size: 100% 2px !important;
    color: rgb(252, 252, 252) !important;
    min-height: 40px;
    /* if linear-gradient, we need to resize it */
}

.rainbowbutton {
    background:
        linear-gradient(to left, #00bfff 0%,
            #5b049e 49%,
            #c004dd 100%) left bottom rgba(0, 12, 23, 0.889) no-repeat;
    background-size: 100% 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    font-size: 14px;
}

.rbt-danger {
    background:
        linear-gradient(to left, #00bfff 0%,
            #5b049e 49%,
            #c004dd 100%) left bottom rgba(69, 0, 0, 0.983) no-repeat;
    background-size: 100% 5px;
}

.rainbowbutton:hover {
    background:
        linear-gradient(to left, #5cd1f8 0%,
            #76409f 49%,
            #c160d0 100%) left bottom rgba(0, 12, 23, 0.889) no-repeat;
    background-size: 100% 5px;
    text-decoration: none;
    color: white;
}

.form-select {
    display: block;
    height: 40px !important;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem !important;
    -moz-padding-start: calc(0.75rem - 3px) !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff !important;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.bg-dark {
    position: -webkit-sticky !important;
}

/* Loading page */

body {
    margin: 0;
}

.contentloading {
    width: 100%;
    height: 100vh;
    background-color: var(--blockbg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentloading .loading {
    width: 80px;
    height: 50px;
    position: relative;
}

.contentloading .loading p {
    top: 0;
    padding: 0;
    margin: 0;
    color: #029530;
    font-family: "Oxygen", sans-serif;
    animation: text 3.5s ease both infinite;
    font-size: 12px;
    letter-spacing: 1px;
}

@keyframes text {
    0% {
        letter-spacing: 1px;
        transform: translateX(0px);
    }

    40% {
        letter-spacing: 2px;
        transform: translateX(26px);
    }

    80% {
        letter-spacing: 1px;
        transform: translateX(32px);
    }

    90% {
        letter-spacing: 2px;
        transform: translateX(0px);
    }

    100% {
        letter-spacing: 1px;
        transform: translateX(0px);
    }
}

.contentloading .loading span {
    background-color: #53a666;
    border-radius: 50px;
    display: block;
    height: 16px;
    width: 16px;
    bottom: 0;
    position: absolute;
    transform: translateX(64px);
    animation: loading 3.5s ease both infinite;
}

.contentloading .loading span:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #a6eec5;
    border-radius: inherit;
    animation: loading2 3.5s ease both infinite;
}

@keyframes loading {
    0% {
        width: 16px;
        transform: translateX(0px);
    }

    40% {
        width: 100%;
        transform: translateX(0px);
    }

    80% {
        width: 16px;
        transform: translateX(64px);
    }

    90% {
        width: 100%;
        transform: translateX(0px);
    }

    100% {
        width: 16px;
        transform: translateX(0px);
    }
}

@keyframes loading2 {
    0% {
        transform: translateX(0px);
        width: 16px;
    }

    40% {
        transform: translateX(0%);
        width: 80%;
    }

    80% {
        width: 100%;
        transform: translateX(0px);
    }

    90% {
        width: 80%;
        transform: translateX(15px);
    }

    100% {
        transform: translateX(0px);
        width: 16px;
    }
}

/* Box coleccion */

.box-container {
    display: flex;
    flex-direction: row;
    margin: 20px;
    flex-wrap: wrap;
}

.custom-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 3rem;
    position: relative;
    width: 220px;
    height: 220px;
    background-color: white;
    border: 1px solid rgba(59, 58, 58, 0.46);
    border-radius: 20px;
    box-shadow: -10px 5px 10px gray;
    top: 0;
    transition: top ease 0.5s;
    margin: 15px;
    font-size: 16px;
    text-decoration: none;
    color: black;
    flex-wrap: wrap;

}

@media (max-width: 992px) {
    .custom-box {
        width: 140px;
        height: 200px;
    }

    .custom-box .MuiAvatar-root {
        max-width: 90px;
        max-height: 90px;
    }
}

.custom-box:hover {
    top: -50px;
}

.custom-card-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 3rem;
    position: relative;
    width: 320px;
    height: 420px;
    background-color: white;
    border: 3px solid rgb(100, 100, 100);
    border-radius: 20px;
    box-shadow: -8px 5px 10px rgb(74, 74, 74);
    top: 0;
    transition: top ease 0.5s;
    margin: 15px;
    font-size: 16px;
    text-decoration: none;
    color: black;
    flex-wrap: wrap;
    background-image: linear-gradient(20deg,
            #ffffff 0%,
            #ffffff 45%,
            #ffffff 100%);

}

.custom-card-box:hover {
    top: -50px;
}

.custom-icon {
    width: 100px;
    height: 100px;
}

.firstline {
    display: block;
    text-decoration: none;
    color: var(--white);
}

.secondline {
    font-size: 12px;
    color: var(--seondary);
}

.containerblock {
    margin: auto !important;
    max-width: 100%;
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 5px;
    background-color: none !important;
}

.block {
    position: relative;
    background: rgb(254, 254, 255) !important;
    background: var(--blockbg) !important;
    box-shadow: 0 4px 12px rgba(178, 121, 252, 0.85);
    background: var(--background);
    backdrop-filter: blur(4px);
    padding: 10px;
    padding-left: 15px;
    border-radius: 15px;
    max-width: 95%;
    margin: auto !important;
    margin-top: 20px !important;
    z-index: 20;
    color: var(--white) !important;
}

.Index20 {
    position: relative;
    z-index: 20;
}

.selectTop {
    position: relative;
    z-index: 100;
}

h4 {
    font-size: 16px;
    background: rgb(52, 115, 195);
    background: var(--seondary);
    background-clip: content-box;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.containerheader {
    /*background-color: var(--primary) !important;*/
    /* color: var(--primary) !important;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    margin-bottom: 5px;
    text-transform: uppercase;
    background-color: none !important;
    width: 100%;
    color: linear-gradient(90deg, rgba(52, 52, 227, 1) 0%, rgba(55, 157, 251, 1) 35%, rgba(34, 105, 190, 1) 100%) !important;
}

.InfoBoxValue {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 27px;
    padding-right: 15px;
}

.InfoBoxText {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centereddiv {
    margin: auto;
    margin-bottom: 15px;
}

.cardquantity {
    font-size: 48px;
    color: var(--primary);
    display: block;
}

.containercoleccion {
    min-height: 250px;
    margin: auto;
    padding: 1rem;
    padding-left: 25px;
    padding-right: 25px;
}

.form-label {
    margin-top: 15px;
}



.login-form {
    border-radius: 10px;
}

@media only screen and (max-width: 1300px) {
    .container-fluid {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .px-4 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

/* CartaItem */

.cartaitem {
    width: 232px;
    height: 345px;
    margin: 1rem;

    position: relative;
    border-radius: 12px;
    overflow: hidden;
    border: none;

    transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
    box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
}

.cartaitem div:nth-of-type(1) {
    position: absolute;
    padding: 16px;
    width: 100%;
    opacity: 0;
    top: 0;
    color: #f9d3b4;
}

.cartaitem:hover {
    box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
    transform: scale(1.05, 1.05);
}

.cartaitem div:nth-of-type(2) {
    width: 100%;
    height: 100%;
}

.cartaitem div:nth-of-type(2) img {
    height: 100%;
    width: 100%;
}

.cartaitem div:nth-of-type(3) {
    z-index: 2;
    background-color: #343739;
    padding: 16px 24px 24px 24px;

    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

.cartaitem div:nth-of-type(3) span {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 500;
    color: #f0f0f0;
}

.cartaitem div:nth-of-type(3) h3 {
    margin-top: 5px;
    font-family: "Roboto Slab", serif;
    color: #f9d3b4;
    text-decoration: none !important;
}

.nodecoration {
    text-decoration: none;
}

.cartaitem:hover div:nth-of-type(2) {
    height: 100%;
    opacity: 1;
}

.cartaitem:hover div:nth-of-type(3) {
    background-color: transparent;
}

.cartaitem:hover div:nth-of-type(1) {
    opacity: 1;
}

@media screen and (max-width: 600px) {
    .app {
        padding: 4rem 2rem;
    }

    .search {
        padding: 1rem 1.75rem;
        width: 100%;
    }

    .search input {
        font-size: 1rem;
    }

    .search img {
        width: 20px;
        height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .app {
        padding: 4rem 1rem;
    }

    h1 {
        font-size: 2rem;
    }

    .container {
        margin-top: 2rem;
    }

    .cartaitem {
        width: "100%";
        margin: 1rem;
    }


}

.carditemcontainer {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.containertitle {
    font-size: 1.8rem;
    /*text-transform: uppercase;
    font-family: "Oxygen", sans-serif;*/

}

input[type="text"],
input[type="password"] {
    border: 0px;
}

.form-label {
    display: block;
    margin-bottom: 10px;
}

input {
    display: block;
    width: 100%;
}


.button-green {
    text-decoration: none;
    color: white;
    background-color: #4CAF50;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.button-green:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
    background-color: #4CAF50;
    color: black;
}

.none {
    text-decoration: none;
    color: white;
}


.selected_container {
    background-color: var(--dark);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 300px;
    height: auto;
    position: relative;
    margin: auto;
    margin-top: 10px;
    border-radius: 10px;
}

.selected_container_header {
    margin: 0px;
    top: 100;
    display: flex;
    font-weight: bold;
    font-size: 10px;
}

.selected_container__info {
    bottom: 20%;
    display: flex;
    align-items: flex-end;
    padding: 10px 30px;
    color: gray;
    font-size: 20px;
}

.selected_container__info span {
    margin-right: 10px;
}

.selected_container__info i {
    margin-right: 10px;
}

.selected_container__profile {
    background: rgb(166, 222, 250);
    background: var(--darkbg1);
    display: flex;
    align-items: center;
    border-radius: 10px 10px 0 0;
    padding: 10px;
    bottom: 0;
}


.selected_container__profile h2 {
    color: var(--white);
    font-size: 1.0rem;
}

.selected_container__profile p {
    color: var(--white);
    font-size: 0.8rem;
}

.selected_container__profile p b {
    font-style: italic;
}

.selected_container__profile__text {
    margin-left: 15px;
}


.img_carta {
    height: 400px;
    width: 300px;
    margin: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px var(--white);
}

.img_carta_medium {
    height: 200px;
    width: 150px;
    margin: 15px;
    border-radius: 10px;
    box-shadow: 0 0 25px blue;
}

.img_carta_small {
    height: 100px;
    width: 75px;
    margin: 5px;
    border-radius: 10px;
    box-shadow: 0 0 5px var(--white);
}

.num_carta_inp {
    height: 50px;
    width: 100px;
}

/* Cuadricula New style */

:root {
    --surface-color: #fff;
    --curve: 40;
}

.cuadricula_cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 250px));
    gap: 2rem;
    margin: 4rem 5vw;
    padding: 0;
    list-style-type: none;
}

.cuadricula_card {
    position: relative;
    display: block;
    height: 100%;
    border-radius: calc(var(--curve) * 1px);
    overflow: hidden;
    text-decoration: none;
    box-shadow: 0px 0px 0px red, 0 0 10px blue;
}

.cuadricula_card__image {
    width: 100%;
    height: 450px;
}


.cuadricula_card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: calc(var(--curve) * 1px);
    background-color: var(--surface-color);
    transform: translateY(100%);
    transition: .2s ease-in-out;
}

.cuadricula_card:hover .card__overlay {
    transform: translateY(0);
}

.cuadricula_card__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 2em;
    border-radius: calc(var(--curve) * 1px) 0 0 0;
    background: rgb(227, 218, 251);
    background: linear-gradient(90deg, rgb(235, 230, 249) 0%, rgb(222, 235, 248) 35%, rgb(227, 249, 240) 100%);
    box-shadow: 2px 2px 4px red, 0 0 25px blue;
    transform: translateY(-100%);
    transition: .2s ease-in-out;
}

.cuadricula_card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
}

.cuadricula_card__arc path {
    fill: var(--surface-color);
}

.cuadricula_card:hover .card__header {
    transform: translateY(0);
}

.cuadricula_card__thumb {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.cuadricula_card__title {
    font-size: 1em;
    margin: 0 0 .3em;
    color: #000000;
}

.cuadricula_card__tagline {
    display: block;
    margin: 0em 0;
    font-family: "MockFlowFont";
    font-size: .8em;
    color: #950101;
}

.cuadricula_card__status {
    font-size: .8em;
    color: #7e7e7e;
}

.cuadricula_card__description {
    padding: 0 2em 2em;
    margin: 0;
    color: #bdc3d7;
    font-family: "MockFlowFont";
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

/* Home block new design */
.home_page-contain {
    display: flex;
    min-height: 1vh;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    background: none;
    padding: 1em;
    margin: auto;
    font-family: 'Open Sans', sans-serif;
    justify-content: center;
}

.home_data-card {
    display: flex;
    flex-direction: column;
    max-width: 14em;
    height: 10.75em;
    overflow: hidden;
    border-radius: .5em;
    text-decoration: none;
    background: white;
    margin: 1em;
    padding: 2.75em 2.5em;
    box-shadow: 0 1.5em 2.5em -.5em rgba(#000000, .1);
    transition: transform .45s ease, background .45s ease;
}

.home_data-card_small {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 14em;
    height: 1em;
    overflow: hidden;
    border-radius: .5em;
    text-decoration: none;
    background: var(--blockbg);
    color: var(--white);
    margin: 1em;
    padding: 2.25em 2em;
    box-shadow: 0 1.5em 2.5em -.5em rgba(#000000, .1);
    transition: transform .45s ease, background .45s ease;
}

.home_data-card_small:hover {
    margin-bottom: 0em;
    height: 1.5em;
    box-shadow: rgba(1, 135, 179, 0.608) 0px 2px 2px 3px;
}

.home_link-card {
    text-decoration: none;
}

.home_link-card:hover {
    color: #01d28e;
}

.home_data-card h3 {
    color: #2E3C40;
    font-size: 3.5em;
    font-weight: 600;
    line-height: 1;
    padding-bottom: .5em;
    margin: 0 0 0.142857143em;
    border-bottom: 2px solid #753BBD;
    transition: color .45s ease, border .45s ease;
}

.home_data-card h4 {
    color: #627084;
    text-transform: uppercase;
    font-size: 1.125em;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.1em;
    margin: 0 0 1.777777778em;
    transition: color .45s ease;
}

.home_data-card p {
    opacity: 0;
    color: #FFFFFF;
    font-weight: 600;
    line-height: 1.8;
    margin: 0 0 1.25em;
    transform: translateY(-1em);
    transition: opacity .45s ease, transform .5s ease;
}

.home_data-card .home_link-text {
    display: block;
    position: sticky;
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.2;
    margin: auto 0 0;
    bottom: 1px;
    transition: color .45s ease;
}

.home_data-card .home_link-text svg {
    margin-left: .5em;
    transition: transform .6s ease;
}

.home_data-card .home_link-text svg path {
    transition: fill .45s ease;
}

.home_data-card:hover {
    background: #a8afdd;
    transform: scale(1.02);
}

.home_data-card:hover h3 {
    color: #FFFFFF;
    border-bottom-color: #A754C4;
}

.home_data-card:hover h4 {
    color: #FFFFFF;
}

.home_data-card:hover p {
    opacity: 1;
    transform: none;
}

.home_data-card:hover .home_link-text {
    color: #FFFFFF;
}

.home_data-card:hover .home_link-text svg {
    animation: point 1.25s infinite alternate;
}

.home_data-card:hover .home_link-text svg path {
    fill: #FFFFFF;
}

@keyframes point {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(.125em);
    }
}

/* Upload button */

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    margin: 10px;
    content: 'Subir Archivo';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 12pt;
}

.custom-file-input .input-file {

    display: none;
}

.custom-file-input:hover::before {
    border-color: black;
}

.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.MenuFooter {
    padding: 15px;
    bottom: 0;
    right: 0;
    top: 20px;
    position: absolute;
    color: var(--white) !important;
}

/* FF Card cuadricula */

.ffbody {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    padding-top: 50px;
}

.ffcard {
    position: relative;
    width: 300px;
    height: 250px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
    transition: 0.5s;
    margin: 15px;
    margin-bottom: 50px;
}

.ffcard:hover {
    height: 400px;
}

.ffimgBx {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    height: 200px;
    background: #fff;
    top: -50px;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.35);
    overflow: hidden;
    transition: 0.5s;
}

.ffcard:hover .ffimgBx {
    width: 175px;
    height: 250px;
}

.ffimgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ffcard .ffcontent {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
}

.ffcard .ffcontent .ffdetails {
    position: absolute;
    padding: 20px;
    padding-top: 0;
    text-align: center;
    width: 100%;
    transition: 0.5s;
    transform: translateY(80px);
}

.ffcard:hover .ffcontent .ffdetails {
    transform: translateY(0px);
}

.ffcard .ffcontent .ffdetails h2 {
    font-size: 1em;
    font-weight: 600;
    color: #555;
    line-height: 1.2em;
}

.ffcard .ffcontent .ffdetails h2 span {
    font-size: 0.75em;
    font-weight: 500;
    opacity: 0.5;
}

.ffcard .ffcontent .ffdetails .ffdata {
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: space-between;
}

.ffcard .ffcontent .ffdetails .ffdata h3 {
    font-size: 1em;
    color: #555;
    line-height: 1.2em;
    font-weight: 600;
}

.ffcard .ffcontent .ffdetails .ffdata h3 span {
    font-size: 0.85em;
    font-weight: 400;
    opacity: 0.5;
}

.qtybuttons {
    background-color: blue;
}

/* Ebay Product Card */

.product-card {
    border: 0px solid rgb(0, 0, 0);
    border-radius: 5px;
    padding: 10px;
    width: 270px;
    height: 420px;
    position: relative;
    display: inline-block;
    margin: 10px;
    /* Posición relativa para poder establecer posición absoluta en el botón */
}

.product-image-container {
    height: 250px;
    overflow: hidden;
}

.product-image {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
}

.product-title {
    font-size: 1rem;
    margin-bottom: 5px;
}

.product-price {
    font-size: 1rem;
    margin-bottom: 5px;
}

.product-endate {
    font-size: 0.8rem;
    margin-bottom: 5px;
}

.product-link {
    display: block;
    background-color: #40689d;
    color: rgb(0, 0, 0);
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.2s ease;
    position: absolute;
    /* Posición absoluta para fijar el botón en la parte inferior */
    bottom: 10px;
    /* Distancia desde abajo */
    left: 50%;
    /* Posición centrada */
    transform: translateX(-50%);
    /* Centra horizontalmente */
}

.product-link:hover {
    background-color: #f5a10e;
}